import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';
import { SEO, SiteMetadata } from '../components/seo/seo';
import { Grid, Typography, Avatar, Hidden } from '@material-ui/core';
import { Schedule, EventAvailable, KeyboardArrowDownRounded, Assignment, FormatAlignRight } from '@material-ui/icons';
import { DefaultHeader } from '../components/Header/Header';
import { makeStyles } from '@material-ui/core/styles';
import { Parallax } from '../components/Parallax/Parallax';
import { DefaultFooter } from '../components/Footer/Footer';
import { Link as GatsbyLink } from 'gatsby';
import Img, { FixedObject, FluidObject } from 'gatsby-image';
import { Button } from '../components/Button/Button';
import { primaryColor, warningColor } from '../styles/materialKitBase';
import { Card } from '../components/Card/Card';
import { CardHeader } from '../components/Card/CardHeader';
import { CardBody } from '../components/Card/CardBody';
import { seriesStyle } from './seriesStyle';

const useStyles = makeStyles(seriesStyle);

type SeriesTemplateProps = PageProps<{
  site: SiteMetadata;
  aSeriesOf: {
    frontmatter: {
      title: string;
      subtitle: string;
      slug: string;
      series: string;
      coverImage: {
        childImageSharp: {
          fixed: FixedObject;
        };
      };
    };
    html: string;
  };
  blogPostsFromSeries: {
    edges: {
      node: {
        id: string;
        frontmatter: {
          image: {
            childImageSharp: {
              fluid: FluidObject;
            };
          };
          intro?: string;
          title: string;
          date: string;
          slug: string;
          mainCategory: string;
          datePretty: string;
          author?: string;
          authorAvatar?: {
            childImageSharp: {
              fixed: FixedObject;
            };
          };
        };
        excerpt: string;
        timeToRead: number;
      };
    }[];
  };
}>;

// TODO: check more https://github.com/nehalist/gatsby-theme-nehalem/blob/master/theme/src/templates/post.tsx
const SeriesTemplate: FC<SeriesTemplateProps> = ({ data }) => {
  const { blogPostsFromSeries, aSeriesOf, site } = data;
  const classes = useStyles();

  return (
    <div style={{ background: 'linear-gradient(#212121, #414345)' }}>
      <SEO
        title={aSeriesOf.frontmatter.title}
        slug={aSeriesOf.frontmatter.slug}
        description={aSeriesOf.html}
        featuredImage={aSeriesOf.frontmatter.coverImage.childImageSharp.fixed.src}
      />
      <DefaultHeader />

      <Parallax
        image={aSeriesOf.frontmatter.coverImage.childImageSharp.fixed.src}
        className={classes.parallax}
        filter="dark"
      >
        <div className={classes.container}>
          <Grid container justify="center">
            <Hidden xsDown>
              <KeyboardArrowDownRounded
                style={{ color: primaryColor[0], bottom: '70px', position: 'absolute', fontSize: '3.2rem' }}
              />
              <KeyboardArrowDownRounded
                style={{
                  color: warningColor[0],
                  bottom: '83px',
                  position: 'absolute',
                  fontSize: '3.2rem',
                  animation: 'bounce 4s infinite',
                }}
              />
            </Hidden>
            <Grid item md={9} className={classes.textCenter}>
              <Typography component="h1" variant="h3" className={classes.title}>
                {aSeriesOf.frontmatter.title}
              </Typography>
              <Typography component="h2" variant="h6" className={classes.title}>
                {aSeriesOf.frontmatter.subtitle}
              </Typography>

              <br />
              <Button color="info" size="lg" round component={GatsbyLink} to="/series">
                Explore Other Series&nbsp;
                <FormatAlignRight />
              </Button>
            </Grid>
          </Grid>
        </div>
      </Parallax>

      <div style={{ background: 'linear-gradient(#212121, #414345)' }}>
        <div className={classes.main} style={{ marginBottom: '60px' }}>
          <div className={classes.container}>
            <Grid container justify="center" spacing={2}>
              {blogPostsFromSeries.edges.map(({ node }, index) => {
                return (
                  <Grid key={node.id} item xs={12} sm={9} md={6}>
                    <Card id={`blog-post-${index}`} blog={true} background={false} className={classes.card4}>
                      <CardHeader image plain>
                        <GatsbyLink to={`/${node.frontmatter.slug}`}>
                          <Img alt={node.frontmatter.title} fluid={node.frontmatter.image.childImageSharp.fluid} />
                        </GatsbyLink>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${node.frontmatter.image.childImageSharp.fluid.src})`,
                            opacity: '1',
                          }}
                        />
                      </CardHeader>
                      <CardBody plain background={false}>
                        <Typography
                          variant="subtitle2"
                          className={`${classes.cardCategory} ${classes[`cardCategory${index % 4}`]}`}
                        >
                          <EventAvailable />
                          <time dateTime={node.frontmatter.date}>{node.frontmatter.datePretty}</time>{' '}
                          <span style={{ padding: '0 8px', lineHeight: '1.5rem' }}>|</span>
                          <Schedule />
                          {node.timeToRead} min read {/*<br />*/}
                          <span style={{ padding: '0 8px', lineHeight: '1.5rem' }}>|</span>
                          <Assignment />
                          {node.frontmatter.mainCategory}
                          <span style={{ padding: '0 8px', lineHeight: '1.5rem' }}>|</span>
                          <Avatar
                            src={
                              node.frontmatter.authorAvatar
                                ? node.frontmatter.authorAvatar.childImageSharp.fixed.src
                                : `${site.siteMetadata.siteUrl}${site.siteMetadata.author.avatar}`
                            }
                            alt={node.frontmatter.author ? node.frontmatter.author : site.siteMetadata.author.name}
                            className={classes.avatarSmall}
                          />
                          {node.frontmatter.author ? node.frontmatter.author : site.siteMetadata.author.name}
                        </Typography>

                        <Typography variant="h5" component="h3" className={classes.cardTitle}>
                          <GatsbyLink to={`/${node.frontmatter.slug}`}>{node.frontmatter.title}</GatsbyLink>
                        </Typography>
                        <Typography variant="subtitle1" component="h5" className={classes.description}>
                          {node.frontmatter.intro ? node.frontmatter.intro : node.excerpt.replace(/^Introduction/, '')}
                        </Typography>
                        <Button round color="primary" component={GatsbyLink} to={`/${node.frontmatter.slug}`}>
                          Read More
                        </Button>
                      </CardBody>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </div>
      </div>
      <DefaultFooter />
    </div>
  );
};

export default SeriesTemplate;

export const query = graphql`
  query Series($seriesId: String!, $series: String!) {
    site {
      siteMetadata {
        siteUrl
        author {
          name
          avatar
        }
      }
    }
    aSeriesOf: markdownRemark(id: { eq: $seriesId }) {
      frontmatter {
        title
        subtitle
        slug
        series
        coverImage {
          childImageSharp {
            fixed(width: 1000) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      html
    }
    blogPostsFromSeries: allMarkdownRemark(
      filter: { frontmatter: { series_main: { eq: null }, series: { eq: $series } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            intro
            slug
            series
            mainCategory
            date
            datePretty: date(formatString: "DD MMMM, YYYY")
            image: coverImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          timeToRead
          excerpt(pruneLength: 150)
        }
      }
    }
  }
`;
